import { AxiosError } from "axios";
import { Util } from "src/utils/Util";
import { PolicyCancellationAdapter } from "../adapters/policy-cancellation.adapter";
import { IReasons } from "../interfaces/IReasons";
import { PolicyCancellationService } from "../services/policy-cancellation.service";

export namespace PolicyCancellationFeature {

  /**
   * 
   * @returns 
   */
  export const cancellationReasonsFeature = async (): Promise<IReasons[] | undefined> => {
    try {
      const { data } = await PolicyCancellationService.cancellationReasonsService();
      const reasons = PolicyCancellationAdapter.cancellationReasonsDataADapter(data);

      return reasons;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   * @param policyId 
   * @param userId 
   * @param reasonId 
   * @param customDescription 
   */
  export const cancelPolicyFeature = async (policyId: string, userId: string, reasonId: string, customDescription?: string): Promise<void | undefined> => {
    try {
      const request = PolicyCancellationAdapter.cancelPolicyRequestAdapter(policyId, userId, reasonId, customDescription);
      await PolicyCancellationService.cancelPolicyService(request);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   * @param policyId 
   */
  export const checkTemporalCancellationFeature = async (policyId: string): Promise<Date | undefined> => {
    try {
      const { data } = await PolicyCancellationService.checkTemporalCancellationService(policyId);
      const cancellationDate = PolicyCancellationAdapter.checkTemporalCancellationAdapter(data);
      return cancellationDate;
    } catch (e) {
      console.error(e);
      const error = e as AxiosError;
      if (error.response?.data.minor === Util.STATUS_CODE.TEMPORAL_CANCELLATION_ERROR_STATUS.ALREADY_CANCELLED) {
        throw new Error('checkPayments')
      } else {
        throw e;
      }
    }
  }

  /**
   * 
   * @param policyNumber 
   */
  export const checkPolicyPaymentsFeature = async (policyNumber: string, policyId: string): Promise<boolean> => {
    try {
      await PolicyCancellationService.checkPolicyPaymentsService(policyNumber);
      return true;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.minor === Util.STATUS_CODE.TEMPORAL_CANCELLATION_ERROR_STATUS.NEXT_PAYMENTS_NOT_FOUND) {
        await cancelAutomaticRenewalFeature(policyId);
        return false;
      }
      else
        throw e;
    }
  }

  /**
   * 
   * @param policyId 
   */  
  const cancelAutomaticRenewalFeature = async (policyId: string): Promise<void> => {
    try {
      const request = PolicyCancellationAdapter.cancelAutomaticRenewalRequestAdapter(policyId);
      await PolicyCancellationService.cancelAutomaticRenewalService(request);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}