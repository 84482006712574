import { SagaReturnType, call, put, takeLatest } from "redux-saga/effects";
import { ClaimRegisterAction } from "../actions/claim-register.action";
import { ClaimRegisterFeature } from "../features/claim-register.feature";
import { ClaimRegisterSlice } from "../slices/claim-register.slice";
import { StatusTypeItem } from "src/classes/claims/StatusTypeItem";
import { Address } from "src/classes/Address";
import { Util } from "src/utils/Util";
import { PayloadAction } from "@reduxjs/toolkit";
import { ClaimRegister } from "src/classes/claims/ClaimRegister";
import { ClaimAdjuster } from "src/classes/claims/ClaimAdjuster";

/**
 *
 */
export namespace ClaimRegisterSaga {

  type StatusTypeList = SagaReturnType<typeof ClaimRegisterFeature.statusTypeFeature>;
  type StatesList = SagaReturnType<typeof ClaimRegisterFeature.countryStatesFeature>;
  type RegisterInfo = SagaReturnType<typeof ClaimRegisterFeature.claimPersonInfoFeature>;
  type AdjuterInfo = SagaReturnType<typeof ClaimRegisterFeature.claimAdjusterInfoFeature>;

  /**
   * 
   */
  function* fillClaimsLists() {
    try {
      const licences: StatusTypeList = yield call(ClaimRegisterFeature.statusTypeFeature, Util.STATUS_CODE.CLAIMS_LISTS_TYPES.LICENCE);
      const colors: StatusTypeList = yield call(ClaimRegisterFeature.statusTypeFeature, Util.STATUS_CODE.CLAIMS_LISTS_TYPES.COLOR);
      const vehicleDamages: StatusTypeList = yield call(ClaimRegisterFeature.statusTypeFeature, Util.STATUS_CODE.CLAIMS_LISTS_TYPES.VEHICLE_DAMAGE, true);
      const bikeDamages: StatusTypeList = yield call(ClaimRegisterFeature.statusTypeFeature, Util.STATUS_CODE.CLAIMS_LISTS_TYPES.BIKE_DAMAGE, true);
      const vehicleTypes: StatusTypeList = yield call(ClaimRegisterFeature.statusTypeFeature, Util.STATUS_CODE.CLAIMS_LISTS_TYPES.VEHICLE_TYPE);
      const damageSeveritiesList: StatusTypeList = yield call(ClaimRegisterFeature.statusTypeFeature, Util.STATUS_CODE.CLAIMS_LISTS_TYPES.DAMAGE_SEVERITIES);
			//const personTypes: StatusTypeList = yield call(ClaimRegisterFeature.statusTypeFeature, Util.STATUS_CODE.CLAIMS_LISTS_TYPES.PERSON_TYPE)
      const causes: StatusTypeList = yield call(ClaimRegisterFeature.causesListFeature);
      const circumstances: StatusTypeList = yield call(ClaimRegisterFeature.statusTypeFeature, Util.STATUS_CODE.CLAIMS_LISTS_TYPES.CIRCUMSTANCE);
      const reasons: StatusTypeList = yield call(ClaimRegisterFeature.statusTypeFeature, Util.STATUS_CODE.CLAIMS_LISTS_TYPES.CANCELLING_REASON);
      const states: StatesList = yield call(ClaimRegisterFeature.countryStatesFeature);
			
      yield put(ClaimRegisterSlice.actions.licenceTypes(licences as StatusTypeItem[]));
      yield put(ClaimRegisterSlice.actions.vehicleColors(colors as StatusTypeItem[]));
      yield put(ClaimRegisterSlice.actions.vehicleDamagesList(vehicleDamages as StatusTypeItem[]));
      yield put(ClaimRegisterSlice.actions.bikeDamagesList(bikeDamages as StatusTypeItem[]));
      yield put(ClaimRegisterSlice.actions.vehicleTypes(vehicleTypes as StatusTypeItem[]));
      yield put(ClaimRegisterSlice.actions.damageSeveritiesList(damageSeveritiesList as StatusTypeItem[]));
			//yield put(ClaimRegisterSlice.actions.personTypes(personTypes as StatusTypeItem[]));
      yield put(ClaimRegisterSlice.actions.causesList(causes as StatusTypeItem[]));
      yield put(ClaimRegisterSlice.actions.circumstancesList(circumstances as StatusTypeItem[]));
      yield put(ClaimRegisterSlice.actions.states(states as Address[]));
			yield put(ClaimRegisterSlice.actions.cancellingReasonsList(reasons as StatusTypeItem[]));
    } catch (e) {

    }
  }

  /**
   * 
   * @param action 
   */
  function* fillListsWithPayload(action: PayloadAction<{adjuster: ClaimAdjuster}>) {
    try {
      const claimId = action.payload.adjuster.claimId;
      const liabilityId = action.payload.adjuster.liability?.id;
      const liabilities: StatusTypeList = yield call(ClaimRegisterFeature.liabilityListFeature, claimId);
      const personTypes: StatusTypeList = yield call(ClaimRegisterFeature.personListFeature, claimId, liabilityId);

      yield put(ClaimRegisterSlice.actions.liabilityList(liabilities as StatusTypeItem[]));
      yield put(ClaimRegisterSlice.actions.personTypes(personTypes as StatusTypeItem[]));
    } catch (e) {

    }
  }

  /**
   * 
   */
  function* claimPersonInfo(action: PayloadAction<{id: string, key: string}>) {
    try {
      yield put(ClaimRegisterSlice.actions.formDisabled(false));
      const registerInfo: RegisterInfo = yield call(ClaimRegisterFeature.claimPersonInfoFeature, action.payload.id, action.payload.key);
      yield put(ClaimRegisterSlice.actions.claimRegister(registerInfo as ClaimRegister));
    } catch (e) {
      //Ignore error, use a boolean instead
      yield put(ClaimRegisterSlice.actions.formDisabled(true));
    }
  }
	
  /**
   * 
   */
  function* claimAdjusterInfo(action: PayloadAction<{id: string, key: string}>) {
    try {
      yield put(ClaimRegisterSlice.actions.formDisabled(false));
      const adjusterInfo: AdjuterInfo = yield call(ClaimRegisterFeature.claimAdjusterInfoFeature, action.payload.id, action.payload.key);
      yield put(ClaimRegisterSlice.actions.claimAdjuster(adjusterInfo as ClaimAdjuster));
    } catch (e) {
      //Ignore error, use a boolean instead
      yield put(ClaimRegisterSlice.actions.formDisabled(true));
    }
  }

  /**
   * 
   */
  function* setNewClaimPersonInfo(action: PayloadAction<ClaimRegister>) {
    yield put(ClaimRegisterSlice.actions.claimRegister(action.payload));
  }

  /**
   * 
   */
  function* setNewClaimAdjusterInfo(action: PayloadAction<ClaimAdjuster>) {
    yield put(ClaimRegisterSlice.actions.claimAdjuster(action.payload));
  }


  export function* claimRegisterSaga() {
    yield takeLatest(ClaimRegisterAction.fillLists.type, fillClaimsLists);
    yield takeLatest(ClaimRegisterAction.fillListsWithPayload.type, fillListsWithPayload);
    yield takeLatest(ClaimRegisterAction.claimPersonForm.type, claimPersonInfo);
    yield takeLatest(ClaimRegisterAction.setNewClaimRegisterInfo.type, setNewClaimPersonInfo);
    yield takeLatest(ClaimRegisterAction.setNewClaimAdjusterInfo.type, setNewClaimAdjusterInfo);
    yield takeLatest(ClaimRegisterAction.claimAdjusterForm.type, claimAdjusterInfo);
  }
}