import { RootState } from "src/store/store";

/**
 *
 */
export namespace ClaimRegisterSelector {

  export const licenceTypes = (state: RootState) => state.claims?.licenceTypes;

  export const vehicleColors = (state: RootState) => state.claims?.vehicleColors;

  export const vehicleDamagesList = (state: RootState) => state.claims?.vehicleDamagesList;

  export const bikeDamagesList = (state: RootState) => state.claims?.bikeDamagesList;

  export const damageSeveritiesList = (state: RootState) => state.claims?.damageSeveritiesList;

	export const serviceTypesList = (state: RootState) => state.claims?.serviceTypesList;
	
  export const causesList = (state: RootState) => state.claims?.causesList;

  export const circumstancesList = (state: RootState) => state.claims?.circumstancesList;

  export const liabilityList = (state: RootState) => state.claims?.liabilityList;

  export const vehicleTypes = (state: RootState) => state.claims?.vehicleTypes;

	export const personTypes = (state: RootState) => state.claims?.personTypes;

  export const reasonsList = (state: RootState) => state.claims?.cancellingReasonsList;

  export const statesList = (state: RootState) => state.claims?.states;
  
  export const registerInfo = (state: RootState) => state.claims?.claimRegister;

  export const adjusterInfo = (state: RootState) => state.claims?.claimAdjuster;

  export const formDisabled = (state: RootState) => state.claims?.formDisabled;
}