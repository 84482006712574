export const statusCode = {

  STRIPE_ERROR_CODE: {
    DECLINED: {
      CODE: 'card_declined',
      DECLINE_CODE: {
        INSUFFICIENT_FUNDS: 'insufficient_funds',
      }
    },
    EXPIRED: 'expired_card',
    INCORRECT_CVC: 'incorrect_cvc',
    PROCESSING: 'processing_error'
  },
  PAYMENT_ERROR_CODE: {
    INVALID_CARD: {
      CODE: 'invalid_card',
      DECLINE_CODE: 'installment_not_available'
    }
  },

  POLICY_STATUS_ACTION: {
    ISSUE: 3,
    CANCEL: 4,
    REHABILITATED: 5,
  },

  POLICY_STATUS: {
    ACTIVE: 1,
    PENDING: 2,
    CANCELLED: 3,
    EXPIRED: 4,
  },

  GENDER: {
    FEMALE: 2,
    MALE: 1,
  },

  CIVIL_STATUS: {
    SINGLE: 1,
    MARRIED: 2,
    DIVORCED: 4,
    OTHER: 9,
  },

  ENDORSEMENT_TYPE_CODE: {
    VIN: 1,
    PLATE: 2,
  },

  ENDORSEMENT_CATEGORY: {
    VEHICLE: 'vehicle'
  },

  IDENTITY_STATUS: {
    INCOMPLETE: 0,
    CREATED: 1,
    COMPLETE: 2,
    DENIED: 3
  },

  IDENTITY_ERROR_STATUS: {
    EXPIRED: 0,
    INVALID: 1,
    DECLINED: 2,
    BLOCKED: 3
  },
  CLAIM_PICTURE_STATUS: {
    PEDING: 0,
    APPROVED: 1,
    DISAPPROVED: 2,
  },

  CLAIM_PROPOSAL_STATUS: {
    NEUTRAL: 0,
    APPROVED: 1,
    DISAPPROVED: 2,
  },

  CLAIM_PROPOSAL_NEGOTATION_STATUS: {
    APPROVE: 1,
  },

	CLAIM_PROVIDERS_TYPES: {
		MULTIBRAND: 0,
		AGENCY: 1,
	},

  DAMAGE_CLAIM_STATUS: {
    NEW: 0,
    PENDING: 1,
    MISSING_DATA: 2,
    WRONG_DATA: 3,
    PENDING_PROPOSAL: 4,
    NEGOTIATING: 5,
    PENDING_PAYMENT: 6,
    PAID: 7
  },

  DAMAGE_CLAIM_ERROR_STATUS: {
    NO_POLICY: 42,
  },

  TEMPORAL_CANCELLATION_ERROR_STATUS: {
    ALREADY_CANCELLED: 40,
    NEXT_PAYMENTS_NOT_FOUND: 18
  },

  REWARD_TYPE_CODE: {
    PRODUCT: 1
  },

  VEHICLE_PICTURE_TYPE_CODES: {
    FRONT: 0,
    RIGHT_SIDE: 1,
    LEFT_SIDE: 2,
    BACK: 3
  },

  VEHICLE_PICTURE_ERROR: {
    DATE: 'DATE',
    BLURRY: 'BLURRY',
    VEHICLE: 'VEHICLE',
    INVALID: 'INVALID',
  },

  PLAN_TYPE: {
    SUBSCRIPTION: 'cfb3737f-fac8-45eb-9830-d071c53c9ebd',
    ANNUAL: '198ecffe-736f-4786-89fd-c0be764609c2',
    MONTHLY: '63f9bd2f-763a-4d16-8013-5609537417cb'
  },

  POLICY_PAYMENT_STATUS: {
    UNKNOW: 0,
    ACTIVE: 1,
    GRACE_PERIOD: 2,
    CANCELLED: 3
  },

  POLICY_PAYMENT_RECORD_STATUS: {
    PAID: 4,
    ERROR: 7,
  },

  POLICY_INVOICE_STATUS: {
    AVAILABLE: 0,
    EXPIRED: 1,
    FINISHED: 2,
    EMPTY: -1,
    FILE_ERROR: -2
  },

  INVOICE_CREATION_ERROR_CODE: {
    POSTAL_CODE: 1,
    REGIME: 2,
    RFC: 3,
    NAME: 4,
    REGIME_CDFI: 5,
  },

  CLAIMS_LISTS_TYPES: {
    LICENCE: '37a8eb3c-feb1-4996-9347-fe73febd07cd',
    VEHICLE_DAMAGE: '14a7dac8-88c0-4a3a-8d93-03e07f1630c8',
    BIKE_DAMAGE: '244e35e4-7896-432c-8039-cb5b044d131c',
    DAMAGE_SEVERITIES: '1bd4ce29-9498-4126-a4f9-3ce69b6807e8',
    COLOR: '0606e739-d23a-4e26-b417-fd8fc330d2b6',
    VEHICLE_TYPE: '77b62651-f2e0-476a-8ace-a457e4aa0a48',
		PERSON_TYPE: '8d132fec-876f-4aba-bf8d-7036aaef9daa',
		CANCELLING_REASON: '6ac45e07-f080-4381-9861-957cece0f85f',
		SERVICE_TYPE: 'bfc0623b-41f2-453b-8685-fea0b18e4cd7',
		COVERAGE: '6f01d737-983d-4a52-9b6a-3e1e29b8b749',
		PROVIDER_TYPE: 'c27e4eb0-9d5b-4860-8a5c-14ba6c1e5f03',
    CAUSE: 'd640d41f-4298-4fd5-8bda-dde42d9b6c72',
    CIRCUMSTANCE: 'e09a2509-a8a1-4f81-b832-9a40054e8322',
    LIABILITY: '12e259d9-2912-4a44-8c43-3d63381b9834',
  },

  CLAIM_LIABILITY: {
    INSURED: '1cbd4096-93f2-4bfc-9a99-4ad937ba4394',
    THIRD_DRIVER: 'b644d8e4-32cc-4b54-b508-e3ac1debf3c7',
    COLIABILITY: 'c6f3bbe9-a93e-469d-aaff-81fcb7175dfd'
  },

	CLAIM_SERVICE_TYPES: {
		REPAIR: '560492f5-bec0-460b-8013-1ba96d74ba0d',
		MEDICAL: '9c2ceeab-8d69-4e76-8107-084ba6a349a3',
		CRANE: 'cb9ec00e-3eed-43df-b81f-0960dec5dcd6',
		LAWYER: 'f5db5087-a0ea-49be-bc3c-d70c35dc6f2f',
		INVESTIGATOR: 'be1bd536-ab23-434b-8edc-37309860adbb',
		CONTRACTOR: 'a3c5dd63-0380-4e89-8047-3c59cc48263b',
		SIPAC: 'b973aa1f-2b55-41ee-a74c-ce0e76a142cc',
		TRADITIONAL_ORDER: '27076fcd-76dd-420e-8bdf-9bc3201e8b10',
		PAYMENT: '15606736-619c-4a6c-ba8d-4fccd6b59d27',
		CLAIM_PAYMENT: 'cc633be6-acca-40b6-829c-0ef320e2b29c',
		NO_COST: '09368804-f990-457f-aee1-545dbf643366',
    ASSISTANCE: 'ca8db2ca-7963-4838-a88e-61417b6619cc'
	},

  CLAIMS_TYPES: {
    ROBBERY: '0d8cbfef-1a6c-4891-b874-7d7e9795ee68',
    WINDSCREEN: 'b0967e71-f680-43b3-ad65-415df1224002',
    COLLISION: 'a31a3903-7819-4d33-8a40-ad06df4e5d4b',
    TOTAL_LOSS: '0d8cbfef-1a6c-4891-b874-7d7e9795ee68'
  },

	CLAIMS_CRANE_CATEGORIES: {
		CONCESSIONED: 'ab3b472c-01bf-481f-9fc6-728e8ebb9e57'
	},

  CLAIMS_VEHICLE_TYPES: {
    CAR: 'd5752e00-5f88-49ed-877e-5489c62f7d35',
    BIKE: '98642490-f2f7-44bc-b4f2-03daba7de0e8'
  },

	CLAIMS_PERSON_TYPES: {
		INSURED: 'bfeff25f-6fd0-4529-99d0-4b21f2e89e8b',
		THIRD_PARTY_DRIVER: '124271a2-19f1-4bb0-9252-d53a1512a8d3',
    CYCLIST: '0d4d4ee3-cbe5-4e19-9257-69c31ab719f2',
    PROPERTY_OWNER: '4ad65a25-251d-4d3b-aee8-5247f68f281e'
	},

	CLAIMS_SERVICE_CATEGORY_TYPES: {
		AGREED_PAYMENT: 'bfc5e676-2055-4d50-aba1-ac4535564ab7'
	},

  CLAIM_PICTURES_TYPES: {
    CLAIMS: {
      OBJECT_TYPE: '128d02b8-343b-4b86-9803-42c58e23e57e',
      NAMES: {
        REFERENCES: 'fbdbb063-47b3-4bf4-a5da-583e493c5d04',
        DUA: '6e4a66d1-4902-4476-a65f-a6e4f9864c58'
      },
    },
    FORM: {
      OBJECT_TYPE: '1b11b930-1a92-4a5a-b23c-14cbaea123e4',
    },
    PERSON: {
      OBJECT_TYPE: '84e9ac36-9819-480e-be23-1027cdba4fce',
      NAMES: {
        LICENSE_FRONT_PICTURE: '51808137-e515-4994-ba25-e788298778c4',
        LICENSE_BACK_PICTURE: '3fe04389-d624-4a7b-9028-0048d54102ef',
        INE_FRONT_PICTURE: '9975da93-eb1d-48fd-9599-3e1e644b97c4',
        INE_BACK_PICTURE: '9f4ab7ad-6699-400a-92d2-3d07e015f7f0',
        DRIVING: '512b3753-4b31-42b1-ab19-a7550330b1cb',
        VIN: 'd7608603-8434-4aca-a820-297e707a2ff3',
        VEHICLE: '082afa4c-d212-4963-a30b-20efd2dd9180',
      }
    },
    DAMAGE: {
      OJBECT_TYPE: '1b11b930-1a92-4a5a-b23c-14cbaea123e4',
      NAMES: {
        PICTURE: '536ef136-5d41-4b8b-a066-f0a27b4306c4'
      } 
    },
    SERVICE: {
      OBJECT_TYPE: '43a1c322-f175-4b19-ad39-e3a0a5463931',
      NAMES: {
        PICTURE: '057c065f-df74-40b4-a4f1-dfb03f7e7679'
      }
    }
  }
}