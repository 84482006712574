import { ClaimRegisterPicture } from "./ClaimRegisterPicture";
import { StatusTypeItem } from "./StatusTypeItem";

export class Damage {

  /**
   *
   */
  private _id: string | undefined;
  set id(_id: string | undefined) { this._id = _id };
  get id(): string | undefined { return this._id };

  /**
   *
   */
  private _type: StatusTypeItem;
  set type(_type: StatusTypeItem) { this._type = _type };
  get type(): StatusTypeItem { return this._type };

  /**
   * 
   */
  private _severity: StatusTypeItem | undefined;
  set severity(_severity: StatusTypeItem | undefined) { this._severity = _severity };
  get severity(): StatusTypeItem | undefined { return this._severity };

	/**
	 * 
	 */
  private _damagePreexistence: boolean;
  set damagePreexistence(_damagePreexistence: boolean) { this._damagePreexistence = _damagePreexistence };
  get damagePreexistence(): boolean { return this._damagePreexistence };

	/**
	 * 
	 */
	private _description: string;
	set description(_description: string) { this._description = _description };
	get description(): string { return this._description };

  /**
   *
   */
  private _pictures: ClaimRegisterPicture[];
  set pictures(_pictures: ClaimRegisterPicture[]) { this._pictures = _pictures };
  get pictures(): ClaimRegisterPicture[] { return this._pictures };

	/** 
	 * 
	 */
	private _eSign: string;
	set eSign(_eSign: string) { this._eSign = _eSign };
	get eSign(): string { return this._eSign };

  /**
   * 
   */
  private _isPrior: boolean;
  set isPrior(_isPrior: boolean) { this._isPrior = _isPrior };
  get isPrior(): boolean { return this._isPrior };

  
  constructor (_type: StatusTypeItem) { 
    this._type = _type;
    this._pictures = [];
		this._damagePreexistence = false;
		this._description = '';
		this._eSign = '';
    this._isPrior = false;
  }
}