import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { Policy } from "src/classes/Policy"
import { CoreSelector } from "src/core/selectors/core.selector";
import { AlertFactory } from "src/factory/alert.factory";
import { LoadingFactory } from "src/factory/loading.factory";
import { POLICY_PATH } from "src/routes/paths/policy.paths";
import { Util } from "src/utils/Util";
import { PolicyAction } from "../actions/policy.action";
import { PolicyCancellationFeature } from "../feature/policy-cancellation.feature";
import { ICancellationForm } from "../interfaces/ICancellationForm";
import { IReasons } from "../interfaces/IReasons";
import { PolicySelector } from "../selectors/policy.selector";

export namespace PolicyCancellationFacade {

  export const useCancellation = () => {
    const selectedPolicy = useSelector(PolicySelector.selectedPolicy);
    const currentUser = useSelector(CoreSelector.currentUser);
    const [reasonsList, setReasonsList] = useState<IReasons[]>([]);
    const [selectedReason, setSelectedReason] = useState<IReasons | undefined>();
    const [cancellationCompleted, setCancellationCompleted] = useState<boolean>(false);
    const isCancelling = LoadingFactory.useLoader();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { register, formState: { errors, isValid }, getValues } = useForm<ICancellationForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    /**
     * 
     */
    const limitDate = (): string => {
      let formatDate: string = '';
      if (selectedPolicy) {
        const myDate = new Date();
        const endDate = new Date(myDate.getFullYear(), myDate.getMonth(), selectedPolicy.expirationDate?.getDate()!);
        const days = Math.ceil((endDate.getTime() - myDate.getTime()) / (1000 * 3600 * 24));
        if (days <= 0) {
          endDate.setMonth(endDate.getMonth() + 1);
        }
        formatDate = Util.TRANSFORM.DATE.formatDate(endDate);
      }

      return formatDate;
    }

    /**
     * 
     */
    const cancelationReasons = async (): Promise<void> => {
      const reasons = await PolicyCancellationFeature.cancellationReasonsFeature();
      if (reasons) {
        setReasonsList(reasons);
      }
    }

    /**
     * 
     * @param index 
     */
    const selectReasonByIndex = (index: number): void => {
      const reason = reasonsList[index];
      if (reason) {
        setSelectedReason(reason);
      }
    }

    /**
     * 
     */
    const cancelPolicy = async (): Promise<void> => {
      try {
        if (isValid && selectedReason && selectedPolicy) {
          isCancelling.set(true);
          await PolicyCancellationFeature.cancelPolicyFeature(selectedPolicy.id, currentUser.id, selectedReason.id, getValues('customReason'));
          dispatch(PolicyAction.changeToCancelStatus(selectedPolicy));
          isCancelling.set(false);
          setCancellationCompleted(true);
        }
      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
        isCancelling.set(false);
      }
    }

    /**
     * 
     */
    useEffect(
      () => {
        if (selectedPolicy && selectedPolicy.isCanceled() && !cancellationCompleted) {
          AlertFactory.errorAlert('La póliza está cancelada.')
          navigate(`${POLICY_PATH.MANAGEMENT}/${selectedPolicy.policyNumber}`)
        }
      }, [navigate, selectedPolicy, cancellationCompleted]
    )

    /**
     * 
     * @returns 
     */
    const useMountEffect = () => useEffect(
      () => {
        cancelationReasons();
      }, []
    )
    useMountEffect();

    return {
      reasonsList, selectedReason, selectReasonByIndex, isCancelling, cancelPolicy, limitDate, cancellationCompleted,
      form: { register, formState: { errors, isValid }, getValues }
    }
  }


  export const useCancellationPrompt = () => {
    const [showCancellationPrompt, setShowCancellationPrompt] = useState<boolean>(false);
    const [showAlreadyCancelledPrompt, setShowAlreadyCancelledPrompt] = useState<boolean>(false);
    const [cancellationDate, setCancellationDate] = useState<Date>();
    const loading = LoadingFactory.useLoader();
    const navigate = useNavigate();
    
    const navigateToCancellation = (policy: Policy) => {
        navigate(`${POLICY_PATH.CANCELLATION}/${policy.policyNumber}`);
    }

    /**
     * 
     * @param policy 
     */
    const checkPayments = async (policy: Policy) => {
      try {
        const _hasNextPayments = await PolicyCancellationFeature.checkPolicyPaymentsFeature(policy.policyNumber, policy.id);
        if (!_hasNextPayments) {
          setShowAlreadyCancelledPrompt(true);
        } else {
          setShowCancellationPrompt(true);
        }
      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
      } finally {
        loading.set(false);
      }
    }

    /**
     * 
     * @param policy 
     */
    const checkTemporalCancellation = async (policy: Policy) => {
      try {
        loading.set(true);
        setCancellationDate(undefined);
        const date = await PolicyCancellationFeature.checkTemporalCancellationFeature(policy.id);
        setCancellationDate(date);
        setShowAlreadyCancelledPrompt(true);
      } catch (e) {
        if ((e as Error).message === 'checkPayments') {
          checkPayments(policy);
        } else {
          AlertFactory.errorAlert((e as Error).message);
          loading.set(false);
        }
      }
    }

    /**
     * 
     * @param policy 
     */
    const validateCancellationFlow = (policy: Policy): void => {
      if (policy.isPlanPaymentAnnual() && (policy.isActive() || policy.toBegin())) {
        window.open(Util.CONSTANT.WEB_REQUESTS, '_blank');
      } else if (policy.isPlanPaymentMonthly() && (policy.isActive() || policy.toBegin())) {
        checkTemporalCancellation(policy);
      }
    }

    /**
     * 
     * @param policy 
     * @returns 
     */
    const getRemainingDaysMessage = (policy: Policy): string => {
      let days: number = 0;
      const myDate = new Date();
      const endDate = new Date(myDate.getFullYear(), myDate.getMonth(), policy.expirationDate?.getDate()!);
      days = Math.ceil((endDate.getTime() - myDate.getTime()) / (1000 * 3600 * 24));

      if (days <= 0) {
        endDate.setMonth(endDate.getMonth() + 1);
        days = Math.ceil((endDate.getTime() - myDate.getTime()) / (1000 * 3600 * 24));
      }

      return `${days > 1 ? `faltan ${days} días` : `falta ${days} día`}`;
    }


    return {
      prompt: { setShowCancellationPrompt, showCancellationPrompt, showAlreadyCancelledPrompt, setShowAlreadyCancelledPrompt },
      validateCancellationFlow, getRemainingDaysMessage, loading, cancellationDate, navigateToCancellation
    }
  }
}